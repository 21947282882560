import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Logo from "../../../img/attractif-logo.png";
import HamburgerMenu from "react-hamburger-menu";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {colors, sizes} from "../../../style/constants.js";
import {graphql, useStaticQuery} from "gatsby";
import User from "../../svgs/user.js";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: props => props.navBarColor,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 999,
    padding: "50px 0 0 0!important",
    "& > div": {
      "& img": {
        height: sizes.SMALL,
        width: "auto",
        paddingBottom: 24,
      },
      "& .hamburger-menu": {
        marginBottom: 16,
        border: "1px solid " + colors.WHITE,
        padding: 8,
        boxSizing: "border-box",
        borderRadius: 5
      },
      "& .links": {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
        "& a, span": {
          color: colors.WHITE,
          fontSize: "1.25em",
          marginLeft: 25,
          paddingBottom: 20,
          [theme.breakpoints.down("md")]: {
            marginLeft: 25,
          },
        },
        "& a:hover": {
          color: colors.TURQUOISE,
        },
        "& .contact-us": {
          background: colors.ATT_BLUE,
          color: colors.TURQUOISE,
          border: "#00ddae 1px solid",
          padding: "5px 20px",
          borderRadius: 10,
          marginBottom: 20,
          [theme.breakpoints.down("md")]: {
            marginBottom: 0,
          },
        },
        "& .contact-us:hover": {
          background: colors.TURQUOISE,
          color: colors.WHITE
        },
      },
    },
    "& > div.opened": {
      zIndex: 999,
      "& .links": {
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100vw",
        height: "calc(100vh - 96px)",
        left: 0,
        top: 96,
        background: "rgba(0,27,46,0.975)",
        "& a, span": {
          display: "inline-block",
          padding: "20px 0",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginLeft: 0,
          boxSizing: "border-box",
          "& :hover": {
            background: colors.TURQUOISE,
            color: colors.WHITE,
          },
        },
        "& .contact-us": {
          border: "none",
          borderRadius: 0,
        },
        "& > div > div": {
          width: "calc(100% + 50px)",
          left: -25
        }
      }
    }
  },
  industries: {
    position: "relative",
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0,
    },
    "& > div": {
      background: colors.ATT_BLUE,
      position: "absolute",
      top: "100%",
      left: 0,
      display: "flex",
      flexFlow: "column",
      width: "calc(100% + 25px)",
      zIndex: 1,
      "& a": {
        textAlign: "center",
        margin: "0!important",
        width: "100%",
        padding: "0 10px 20px 10px!important",
        boxSizing: "border-box",
      },
      "& a:first-child": {
        borderTop: colors.TURQUOISE + " 2px solid",
        paddingTop: "20px!important"
      }
    }
  },
  user: {
    position: "relative",
    paddingBottom: 20,
    "& span": {
      padding: "5px!important",
      background: colors.ATT_BLUE,
      border: "1px solid" + colors.LIGHT_BLUE,
      borderRadius: "50%",
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        "width": "100%"
      }
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0,
    },
    "& > div": {
      background: colors.ATT_BLUE,
      position: "absolute",
      top: "100%",
      left: -37.5,
      display: "flex",
      flexFlow: "column",
      width: "calc(100% + 100px)",
      zIndex: 1,
      "& a": {
        textAlign: "center",
        margin: "0!important",
        width: "100%",
        padding: "0 10px 20px 10px!important",
        boxSizing: "border-box",
      },
      "& a:first-child": {
        borderTop: colors.TURQUOISE + " 2px solid",
        paddingTop: "20px!important"
      }
    }
  }
}));

const Nav = (props) => {

  const data = useStaticQuery(graphql`
    query NavIndustriesList {
      allContentfulSecteurs(sort: {fields: createdAt}) {
        edges {
          node {
            name
            permalink
            node_locale
            contentful_id
          }
        }
      }
    }
  `)

  const [state, setState] = useState({
    industriesAreShown: false,
    signageIsShown: false,
    userLinksAreShown: false,
    open: false,
    hamburgerIsShown: false
  });

  const handleClick = () => {
    if (window.innerWidth < 1280) {
      setState({
        industriesAreShown: state.industriesAreShown,
        signageIsShown: state.signageIsShown,
        userLinksAreShown: state.userLinksAreShown,
        open: !state.open,
        hamburgerIsShown: state.hamburgerIsShown
      });
    }
  } 

  const renderHamburgerMenu = () => {
    if (state.hamburgerIsShown === true) {
      return (  
        <div className="hamburger-menu">
          <HamburgerMenu
          isOpen={state.open}
          menuClicked={handleClick}
          width={20}
          height={12}
          strokeWidth={1}
          rotate={0}
          color="white"
          borderRadius={0}
          animationDuration={0.5}
          />
        </div>
      );
    }
  }

  const renderIndustries = () => {
    return data.allContentfulSecteurs.edges.map((industry, index) => {
      return (
        industry.node.node_locale === props.lang ? 
        industry.node.contentful_id === "2ucuM8w25zf95SjOxURGBL" ?
        <a href="https://www.micotv.ca/" target="_blank" rel="noopener noreferrer" key={index} onClick={handleClick}>{industry.node.name}</a> :
        <Link to={industry.node.permalink} key={index} onClick={handleClick}>{industry.node.name}</Link>
        : ""
      );
    });
  };

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setState({
        industriesAreShown: state.industriesAreShown,
        signageIsShown: state.signageIsShown,
        userLinksAreShown: state.userLinksAreShown,
        open: state.open,
        hamburgerIsShown: true
      });
    }
    else {
      setState({
        industriesAreShown: state.industriesAreShown,
        signageIsShown: state.signageIsShown,
        userLinksAreShown: state.userLinksAreShown,
        open: state.open,
        hamburgerIsShown: false
      });
    }

    window.onresize = () => {
      if (window.innerWidth < 1280) {
        setState({
          industriesAreShown: state.industriesAreShown,
          signageIsShown: state.signageIsShown,
          userLinksAreShown: state.userLinksAreShown,
          open: state.open,
          hamburgerIsShown: true
        });
      }
      else {
        setState({
          industriesAreShown: state.industriesAreShown,
          signageIsShown: state.signageIsShown,
          userLinksAreShown: state.userLinksAreShown,
          open: state.open,
          hamburgerIsShown: false
        });
      }
    };
  }, [state.open, state.industriesAreShown, state.signageIsShown, state.userLinksAreShown]);

  const classes = useStyles(props);

  return (
    <section className={classes.wrapper} id="navbar">
      <Container className={state.open ? "opened container" : "container"}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {props.lang === "en-CA" ? <Link to="/en"><img src={Logo} alt="Attractif - Digital signage" /></Link> : <Link to="/"><img src={Logo} alt="Attractif - Affichage dynamique" /></Link>}
          </Grid>
          <Grid item>
            {renderHamburgerMenu()}
            {props.lang === "en-CA" ? 
            <Grid container alignItems="center"className="links">
              <div aria-hidden="true" onMouseEnter={() => setState({industriesAreShown: true, signageIsShown: false, userLinksAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})} className={classes.industries} onMouseLeave={() => setState({industriesAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})}>
                <Link onClick={handleClick} to="/en/industries">Our industries</Link>
                <div aria-hidden="true">{state.industriesAreShown ? renderIndustries() : ""}</div>
              </div>
              <div aria-hidden="true" onMouseEnter={() => setState({industriesAreShown: false, signageIsShown: true, userLinksAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})} className={classes.industries} onMouseLeave={() => setState({industriesAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})}>
                <span>Digital signage</span>
                <div aria-hidden="true">{state.signageIsShown ? 
                <>
                <Link onClick={handleClick} to="/en/software">Software</Link>
                <Link onClick={handleClick} to="/en/content">Content</Link>
                <Link onClick={handleClick} to="/en/pricing">Pricing</Link>
                <Link onClick={handleClick} to="/en/hardware">Hardware</Link>
                </> : ""}</div>
              </div>
              <Link onClick={handleClick} to="/en/queue-management">Queue management</Link>
              <Link onClick={handleClick} to="/en/touch-screens">Touch screens</Link>
              <Link onClick={handleClick} className="contact-us" to="/en/contact-us">Contact us</Link>
              {state.hamburgerIsShown ? 
              <>
                <Link onClick={handleClick} to={props.switchLink}>Français</Link>
                <a target="_blank" rel="noreferrer" onClick={handleClick} href="https://admin.attractif.io/users/sign_in?locale=en">Login</a>
              </> : ""}
            </Grid>
            : 
            <Grid container alignItems="center" className="links">
              <div aria-hidden="true" onMouseEnter={() => setState({industriesAreShown: true, signageIsShown: false, userLinksAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})} className={classes.industries} onMouseLeave={() => setState({industriesAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})}>
                <Link onClick={handleClick} to="/secteurs">Nos secteurs</Link>
                <div aria-hidden="true">{state.industriesAreShown ? renderIndustries() : ""}</div>
              </div>
              <div aria-hidden="true" onMouseEnter={() => setState({industriesAreShown: false, signageIsShown: true, userLinksAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})} className={classes.industries} onMouseLeave={() => setState({industriesAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})}>
                <span>Affichage dynamique</span>
                <div aria-hidden="true">{state.signageIsShown ? 
                <>
                <Link onClick={handleClick} to="/logiciel">Logiciel</Link>
                <Link onClick={handleClick} to="/contenu">Contenu</Link>
                <Link onClick={handleClick} to="/tarifs">Tarifs</Link>
                <Link onClick={handleClick} to="/equipement">Équipement</Link>
                </> : ""}</div>
              </div>
              <Link onClick={handleClick} to="/gestion-de-file-dattente">Gestion de file d"attente</Link>
              <Link onClick={handleClick} to="/bornes-tactiles">Bornes tactiles</Link>
              <Link onClick={handleClick} className="contact-us" to="/nous-parler">Nous parler</Link>
              {state.hamburgerIsShown ? 
              <>
                <Link onClick={handleClick} to={props.switchLink}>English</Link>
                <a target="_blank" rel="noreferrer" onClick={handleClick} href="https://admin.attractif.io/users/sign_in?locale=fr">Connexion</a>
              </> : ""}
            </Grid>}
          </Grid>
          {state.hamburgerIsShown ? "" : 
          <Grid item>
            <Grid container alignItems="center" className="links">
              <div aria-hidden="true" onMouseEnter={() => setState({industriesAreShown: false, signageIsShown: false, userLinksAreShown: true, open: state.open, hamburgerIsShown: state.hamburgerIsShown})} className={classes.user} onMouseLeave={() => setState({industriesAreShown: false, open: state.open, hamburgerIsShown: state.hamburgerIsShown})}>
                <span><User/></span>
                <div aria-hidden="true">{state.userLinksAreShown ? props.lang === "en-CA" ? 
                <>
                <Link onClick={handleClick} to={props.switchLink}>Français</Link>
                <a target="_blank" rel="noreferrer" onClick={handleClick} href="https://admin.attractif.io/users/sign_in?locale=en">Login</a>
                </> :
                <>
                <Link onClick={handleClick} to={props.switchLink}>English</Link>
                <a target="_blank" rel="noreferrer" onClick={handleClick} href="https://admin.attractif.io/users/sign_in?locale=fr">Connexion</a>
                </> : ""}</div>
              </div>
            </Grid>
          </Grid>
          }
        </Grid>
      </Container>
    </section>
  );
}

export default Nav;